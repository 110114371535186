
// INDEX

/*
 * hide / show scroll down arrow depending on visibilit of play-demo-button
 */

const scrollDownArrowElement = document.getElementById("scroll-down-arrow");
const playDemoButtonElement = document.getElementById("play-demo-button");


function onVisibilityChange(el, callback) {
    let old_visible;
    return function () {
        let visible = isElementInViewport(el);
        if (visible != old_visible) {
            old_visible = visible;
            if (typeof callback == 'function') {
                callback();
            }
        }
    }
}

const handler = onVisibilityChange(playDemoButtonElement, function () {
    if (isElementInViewport(playDemoButtonElement)) {
        scrollDownArrowElement.classList.add("hidden");
    } else {
        scrollDownArrowElement.classList.remove("hidden");
    }
});


function isElementInViewport(el) {
    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

document.addEventListener('DOMContentLoaded', handler);
document.addEventListener('load', handler);
document.addEventListener('scroll', handler);
document.addEventListener('resize', handler);



// Menu hide scroll down, show scroll up

/* const body = document.body;
const header = document.querySelector("div.pos-f-t");
const main = document.querySelector("body");
const headerHeight = document.querySelector("div.pos-f-t").offsetHeight;
main.style.top = headerHeight + "px";
let lastScroll = 0;
window.addEventListener("scroll", () => {
  let currentScroll = window.pageYOffset;
  if (currentScroll - lastScroll > 0) {
    header.classList.add("scroll-down");
    header.classList.remove("scroll-up");
  } else {
    // scrolled up -- header show
    header.classList.add("scroll-up");
    header.classList.remove("scroll-down");
  }
  lastScroll = currentScroll;
}) */



/// close navbar on click anywhere in the document
/* $(document).click(function (event) {

  /// If *collapse* is not among targets of event
  if (!$(event.target).is('.collapse *')) {

    /// Collapse every *collapse*
    $('.collapse').collapse('hide');

  }
}); */

/// close navbar on click anywhere in the document
$(document).click(function (event) {

  /// If *collapse* is not among targets of event
  if (!$(event.target).is('.collapse *')) {

    /// Collapse every *collapse*
    $('.collapse').collapse('hide');

  }
});



// COOKIE CONSENT

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + ";expires=" + expires + "; path=/; domain=.micromacro-game.com";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function cookieConsent() {
    if (!getCookie('cookieconsent_status')) {
        $('.consentpopup').toast('show');
    } else if (getCookie('cookieconsent_status')) {
        $('.consentpopup').toast('hide'); }

}

$('#btnDeny').click(()=>{
    setCookie('cookieconsent_status','deny',);
    $('.consentpopup').toast('hide');
})

$('#btnAccept').click(()=>{
    setCookie('cookieconsent_status','allow',7,);
    $('.consentpopup').toast('hide'); 
    /*gtag('consent', 'update', 'G-MNZ1CPKESD', {
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });*/
    
})


// load
cookieConsent()

// for demo / testing only
$('#btnReset').click(()=>{
    // clear cookie to show toast after acceptance
    eraseCookie('cookieconsent_status');
    $('.consentpopup').toast('show');
})


// EXTRA CASES

const btn_s_ccm = document.getElementById("btn-cc-m");
const btn_s_fhm = document.getElementById("btn-fh-m");
const btn_s_aim = document.getElementById("btn-ai-m");
const btn_s_sdm = document.getElementById("btn-sd-m");

const btn_s_cc = document.getElementById("btn-cc");
const s_cc = document.getElementById("crimecity");
const btn_s_fh = document.getElementById("btn-fh");
const s_fh = document.getElementById("fullhouse");
const btn_s_ai = document.getElementById("btn-ai");
const s_ai = document.getElementById("allin");
const btn_s_sd = document.getElementById("btn-sd");
const s_sd = document.getElementById("showdown");

const btn_s_01 = document.getElementById("btn-solution-01");
const s_01 = document.getElementById("solution-01");
const btn_s_02 = document.getElementById("btn-solution-02");
const s_02 = document.getElementById("solution-02");
const btn_s_03 = document.getElementById("btn-solution-03");
const s_03 = document.getElementById("solution-03");
const btn_s_04 = document.getElementById("btn-solution-04");
const s_04 = document.getElementById("solution-04");

const btn_s_05 = document.getElementById("btn-solution-05");
const s_05 = document.getElementById("solution-05");
const btn_s_06 = document.getElementById("btn-solution-06");
const s_06 = document.getElementById("solution-06");
const btn_s_07 = document.getElementById("btn-solution-07");
const s_07 = document.getElementById("solution-07");
const btn_s_08 = document.getElementById("btn-solution-08");
const s_08 = document.getElementById("solution-08");

const btn_s_09 = document.getElementById("btn-solution-09");
const s_09 = document.getElementById("solution-09");
const btn_s_10 = document.getElementById("btn-solution-10");
const s_10 = document.getElementById("solution-10");
const btn_s_11 = document.getElementById("btn-solution-11");
const s_11 = document.getElementById("solution-11");
const btn_s_12 = document.getElementById("btn-solution-12");
const s_12 = document.getElementById("solution-12");

const btn_s_13 = document.getElementById("btn-solution-13");
const s_13 = document.getElementById("solution-13");
const btn_s_14 = document.getElementById("btn-solution-14");
const s_14 = document.getElementById("solution-14");
const btn_s_15 = document.getElementById("btn-solution-15");
const s_15 = document.getElementById("solution-15");


function toggleSCCM()
{		
     btn_s_ccm.classList.toggle("open");	
}

function toggleSFHM()
{		
     btn_s_fhm.classList.toggle("open");
}

function toggleSAIM()
{			
    btn_s_aim.classList.toggle("open");
}

function toggleSSDM()
{			
    btn_s_sdm.classList.toggle("open");
}

function toggleSCC()
{		
     btn_s_cc.classList.toggle("open");
     btn_s_fh.classList.remove("open");
     btn_s_ai.classList.remove("open");
     btn_s_sd.classList.remove("open");		
     btn_s_cc.classList.remove("collapsed");

}


function toggleSFH()
{		
     btn_s_fh.classList.toggle("open");
     btn_s_cc.classList.remove("open");
     btn_s_ai.classList.remove("open");
     btn_s_sd.classList.remove("open");
     btn_s_fh.classList.remove("collapsed");

}

function toggleSAI()
{			
    btn_s_ai.classList.toggle("open");
    btn_s_fh.classList.remove("open");
    btn_s_cc.classList.remove("open");
    btn_s_sd.classList.remove("open");
    btn_s_ai.classList.remove("collapsed");	

}

function toggleSSD()
{			
    btn_s_sd.classList.toggle("open");
    btn_s_fh.classList.remove("open");
    btn_s_cc.classList.remove("open");
    btn_s_ai.classList.remove("open");
    btn_s_sd.classList.remove("collapsed");	

}

function toggleS01()
{
  btn_s_01.classList.toggle("open");
  //s_01.classList.toggle("hidden");
}


function toggleS02()
{
  btn_s_02.classList.toggle("open");
  //s_02.classList.toggle("hidden");
}

function toggleS03()
{
  btn_s_03.classList.toggle("open");
  //s_03.classList.toggle("hidden");
}

function toggleS04()
{
  btn_s_04.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}

function toggleS05()
{
  btn_s_05.classList.toggle("open");
  //s_01.classList.toggle("hidden");
}


function toggleS06()
{
  btn_s_06.classList.toggle("open");
  //s_02.classList.toggle("hidden");
}

function toggleS07()
{
  btn_s_07.classList.toggle("open");
  //s_03.classList.toggle("hidden");
}

function toggleS08()
{
  btn_s_08.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}

function toggleS09()
{
  btn_s_09.classList.toggle("open");
  //s_01.classList.toggle("hidden");
}


function toggleS10()
{
  btn_s_10.classList.toggle("open");
  //s_02.classList.toggle("hidden");
}

function toggleS11()
{
  btn_s_11.classList.toggle("open");
  //s_03.classList.toggle("hidden");
}

function toggleS12()
{
  btn_s_12.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}

function toggleS13()
{
  btn_s_13.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}

function toggleS14()
{
  btn_s_14.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}

function toggleS15()
{
  btn_s_15.classList.toggle("open");
  //s_04.classList.toggle("hidden");
}


btn_s_cc.addEventListener('click', () => toggleSCC());
btn_s_fh.addEventListener('click', () => toggleSFH());
btn_s_ai.addEventListener('click', () => toggleSAI());
btn_s_sd.addEventListener('click', () => toggleSSD());

btn_s_ccm.addEventListener('click', () => toggleSCCM());
btn_s_fhm.addEventListener('click', () => toggleSFHM());
btn_s_aim.addEventListener('click', () => toggleSAIM());
btn_s_sdm.addEventListener('click', () => toggleSSDM());

btn_s_01.addEventListener('click', () => toggleS01());
btn_s_02.addEventListener('click', () => toggleS02());
btn_s_03.addEventListener('click', () => toggleS03());
btn_s_04.addEventListener('click', () => toggleS04());

btn_s_05.addEventListener('click', () => toggleS05());
btn_s_06.addEventListener('click', () => toggleS06());
btn_s_07.addEventListener('click', () => toggleS07());
btn_s_08.addEventListener('click', () => toggleS08());

btn_s_09.addEventListener('click', () => toggleS09());
btn_s_10.addEventListener('click', () => toggleS10());
btn_s_11.addEventListener('click', () => toggleS11());
btn_s_12.addEventListener('click', () => toggleS12());
btn_s_13.addEventListener('click', () => toggleS13());
btn_s_14.addEventListener('click', () => toggleS14());
btn_s_15.addEventListener('click', () => toggleS15());